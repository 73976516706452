<template>
  <div v-bind="$attrs" class="btn button" :class="buttonClass" :id="buttonId">
    <slot></slot>
  </div>
</template>

<script>
/**
 * Custom Button: `<BaseButton>` component.
 *
 * @author Wesley Hutchinson
 * @license EULA
 *
 * introduction: This is the standard button for the app.
 * description: It's a button what more is there to say, it has default hover a click behaviour, but can take icon props and render position, also can take different colours"
 *
 * <BaseButton class="modal-button-ok" buttonClass="gradient-red" id="buttonId">Button Text</BaseButton>`
 */
export default {
  name: "BaseButton",
  props: {
    buttonClass: {
      type: String,
      /**
       * Used to pass class to button - usually uses to pass colours from global css
       */
    },
    buttonId: {
      type: String,
      /**
       * Used to pass id of button"
       */
    },
  },
};
</script>

<style scoped>
.button {
  border-radius: 3px !important;
  padding: 0.4em 3em;
  outline-style: none !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -ms-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}

.button:hover {
  cursor: pointer;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
}

.button:focus,
.button:active {
  outline: none !important;
  box-shadow: none !important;
}

.button:active,
.button:active:focus {
  transform: translateY(4px) !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.button:disabled {
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: none;
  background: #eeeeee;
}

.button.-fill-gray {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}
.button.-size-small {
  height: 32px;
}
.button.-icon-right {
  text-align: left;
  padding: 0 20px;
}
.button.-icon-right > .icon {
  margin-left: 10px;
}
.button.-icon-left {
  text-align: right;
  padding: 0 20px;
}
.button.-icon-left > .icon {
  margin-right: 10px;
}
.button.-icon-center {
  padding: 0 20px;
}
</style>