import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView"),
    },
    {
      path: "",
      name: "home",
      component: () => import("@/views/HomeView"),
    },
    {
      path: "/projects",
      name: "what",
      component: () => import("@/views/WhatView"),
    },
    {
      path: "/how_to_vizsla",
      name: "how",
      component: () => import("@/views/HowView"),
    },
    {
      path: "/why_vizsla",
      name: "why",
      component: () => import("@/views/WhyView"),
    },
    {
      path: "/contact",
      name: "magic",
      component: () => import("@/views/ContactView"),
    },
  ],
});

export default router;
