<template>
  <div>
    <b-navbar id="mainNav" toggleable="sm" :class="{ bgdark: bg_dark }">
      <router-link :to="{ name: 'home' }">
        <b-navbar-brand class="logo"
          ><img alt="Vizsla" id="main_logo" src="@/assets/img/vizsla-nav.png"
        /></b-navbar-brand>
      </router-link>

      <b-navbar-nav class="show-on-md ml-auto">
        <b-nav-item>
          <router-link :to="{ name: 'why' }">Why Vizla</router-link>
        </b-nav-item>
        <b-nav-item disabled> | </b-nav-item>
        <b-nav-item>
          <router-link :to="{ name: 'what' }">Projects</router-link>
        </b-nav-item>
        <b-nav-item disabled> | </b-nav-item>
        <b-nav-item>
          <router-link :to="{ name: 'how' }">How-to-Vizsla</router-link>
        </b-nav-item>
        <b-nav-item disabled> | </b-nav-item>
        <b-nav-item>
          <router-link :to="{ name: 'magic' }">Contact</router-link>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      bg_dark: false,
    };
  },
  methods: {
    scrollTo(id) {
      // prettier-ignore
      document.getElementsByTagName("section")[id].scrollIntoView({ behavior: "smooth" });
    },
  },
  watch: {
    $route(to) {
      if (to.meta.bgColor == "dark") {
        this.bg_dark = true;
      } else {
        this.bg_dark = false;
      }
      // console.log(this.bg_dark);
    },
  },
};
</script>

<style scoped>
#main_logo {
  height: 3vw;
  min-height: 40px;
  position: absolute;
  top: 10px;
}

a,
.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bgdark {
  background-color: transparent !important;
  color: #ffffff !important;
}

.bgdark a.nav-link,
.bgdark a {
  color: #ffffff !important;
}

a:hover,
.nav-link:hover,
a:active,
.nav-link:active,
a:focus,
.nav-link:focus {
  color: #ffa500 !important;
}
#mainNav {
  background-color: transparent;
  height: 70px;
  padding: 30px 10px;
  z-index: 20;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.orange {
  color: #ffa500 !important;
}

@media (min-width: 640px) {
  #mainNav {
    padding: 30px 50px;
  }
}
</style>