<template>
  <div>
    <BaseNavBar></BaseNavBar>
    <transition name="overlay" mode="out-in">
      <router-view> </router-view>
    </transition>
    <div class="overlay-left"></div>

    <!-- <BaseFooter></BaseFooter> -->
    <div id="right"></div>
    <div id="bottom"></div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./assets/styles/main.css";
@import "./assets/styles/padding.css";

#right {
  position: fixed;
  z-index: 21;
  height: 100vh;
  width: 5px;
  right: 0;
  top: 0;
  bottom: 0;
  background: #e28d00;
  transition-duration: 0.3s;
}

#bottom {
  width: 100vw;
  height: 5px;
  background-color: #e28d00;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  transition-duration: 0.3s;
}

.overlay-leave-to ~ #right {
  width: 130vw;
}

.overlay-leave-active ~ #right {
  width: 130vw;
}

.overlay-leave-to ~ #bottom {
  height: 130vh;
}

.overlay-leave-active ~ #bottom {
  height: 130vh;
}

.overlay-enter-active {
  transition-duration: 0.2s;
}
.overlay-leave-active {
  transition-duration: 0.3s;
}
</style>

