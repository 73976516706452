<template>
  <div class="features-wrap">
    <a :href="href" class="feature is-revealing link-card">
      <b-row>
        <b-col sm="3" class="feature-header card-content">
          <img :src="image_src" alt="background" />
        </b-col>
        <b-col sm="8" offset-sm="1">
          <h4 class="feature-title h3-mobile m-0 hero-subtitle">
            {{ title }}
          </h4>

          <p class="hero-paragraph">
            {{ detail }}
          </p>
        </b-col>
      </b-row>
    </a>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
    },
    detail: {
      type: String,
    },
    image: {
      type: String,
    },
    href: {
      type: String,
    },
  },
  computed: {
    image_src: function () {
      return require("@/assets/img/" + this.image);
    },
  },
};
</script>

<style scoped>
.features-wrap {
  padding-top: 80px;
  margin-right: 0;
  margin-left: 0;
}

.feature-header img {
  height: 150px;
  width: auto;
  display: inline;
}

.feature-inner {
  padding: 10px;
}

.hero-paragraph {
  padding-top: 20px;
}
</style>